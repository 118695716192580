import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/UserLayout'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () => import('../User/Login'),
              LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () => import('../User/Register'),
              LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () => import('../User/RegisterResult'),
              LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
    ],
  },
  {
    path: '/productInfo/:data',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
              m => {
                return { namespace: 'audit', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
              m => {
                return { namespace: 'code', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
              m => {
                return { namespace: 'customerService', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
              m => {
                return { namespace: 'developer', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
              m => {
                return { namespace: 'dispute', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
              m => {
                return { namespace: 'email_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
              m => {
                return { namespace: 'evidence', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
              m => {
                return { namespace: 'export', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
              m => {
                return { namespace: 'gateway', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
              m => {
                return { namespace: 'order_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
              m => {
                return { namespace: 'order', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
              m => {
                return { namespace: 'origin_payment', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
              m => {
                return { namespace: 'refundLabel', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
              m => {
                return { namespace: 'saas', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
              m => {
                return { namespace: 'sub', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
              m => {
                return { namespace: 'transaction', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
              m => {
                return { namespace: 'xborder', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
              m => {
                return { namespace: 'xborderChargeback', ...m.default };
              },
            ),
          ],
          component: () => import('../Merchant/Order/ProDetails'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Merchant/Order/ProDetails').default,
    exact: true,
  },
  {
    path: '/productInfoEc/:data',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
              m => {
                return { namespace: 'audit', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
              m => {
                return { namespace: 'code', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
              m => {
                return { namespace: 'customerService', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
              m => {
                return { namespace: 'developer', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
              m => {
                return { namespace: 'dispute', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
              m => {
                return { namespace: 'email_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
              m => {
                return { namespace: 'evidence', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
              m => {
                return { namespace: 'export', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
              m => {
                return { namespace: 'gateway', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
              m => {
                return { namespace: 'order_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
              m => {
                return { namespace: 'order', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
              m => {
                return { namespace: 'origin_payment', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
              m => {
                return { namespace: 'refundLabel', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
              m => {
                return { namespace: 'saas', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
              m => {
                return { namespace: 'sub', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
              m => {
                return { namespace: 'transaction', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
              m => {
                return { namespace: 'xborder', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
              m => {
                return { namespace: 'xborderChargeback', ...m.default };
              },
            ),
          ],
          component: () => import('../Merchant/Order/ProDetails'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Merchant/Order/ProDetails').default,
    exact: true,
  },
  {
    path: '/orderDetails/:id',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
              m => {
                return { namespace: 'audit', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
              m => {
                return { namespace: 'code', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
              m => {
                return { namespace: 'customerService', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
              m => {
                return { namespace: 'developer', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
              m => {
                return { namespace: 'dispute', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
              m => {
                return { namespace: 'email_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
              m => {
                return { namespace: 'evidence', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
              m => {
                return { namespace: 'export', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
              m => {
                return { namespace: 'gateway', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
              m => {
                return { namespace: 'order_center', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
              m => {
                return { namespace: 'order', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
              m => {
                return { namespace: 'origin_payment', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
              m => {
                return { namespace: 'refundLabel', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
              m => {
                return { namespace: 'saas', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
              m => {
                return { namespace: 'sub', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
              m => {
                return { namespace: 'transaction', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
              m => {
                return { namespace: 'xborder', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
              m => {
                return { namespace: 'xborderChargeback', ...m.default };
              },
            ),
          ],
          component: () => import('../Merchant/Order/Details'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Merchant/Order/Details').default,
    exact: true,
  },
  {
    path: '/operation/detail/:id',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
              m => {
                return { namespace: 'export', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
              m => {
                return { namespace: 'orderExport', ...m.default };
              },
            ),
            import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
              m => {
                return { namespace: 'successRate', ...m.default };
              },
            ),
          ],
          component: () =>
            import('../Operation/LogisticsRefund/LogisticsRefundDetail.js'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Operation/LogisticsRefund/LogisticsRefundDetail.js')
          .default,
    authority: ['customer-service-supervisor', 'documentary'],
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BasicLayout'),
          LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    routes: [
      {
        path: '/',
        redirect: '/manage/dispute',
        exact: true,
      },
      {
        path: '/merchant',
        name: 'merchant',
        icon: 'money-collect',
        authority: [
          'manage_disputes',
          'manage_accounts',
          'manage_transactions',
          'manage_export_tasks',
          'sale_mail',
          'manage_transactions_en',
          'manage_transactions_ec',
          'manage_xborder_dispute',
          'manage_audits',
          'manage_orders',
          'manage_google_verify_code',
          'manage_sub_account',
          'transactions_show',
          'transactions_show_en',
          'orders_show',
          'manage_saas_disputes',
          'orders_ec_show',
        ],
        routes: [
          {
            path: '/merchant/account',
            name: 'gateway',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Account/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Account/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_accounts'],
            exact: true,
          },
          {
            path: '/merchant/sub',
            name: 'sub',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/SubAccount/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/SubAccount/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_sub_account'],
            exact: true,
          },
          {
            path: '/merchant/transaction',
            name: 'transaction',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Transaction/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Transaction/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_transactions'],
            exact: true,
          },
          {
            path: '/merchant/transactionEn',
            name: 'Activity',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/Transaction/TransactionEn'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Transaction/TransactionEn').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_transactions_en'],
            exact: true,
          },
          {
            name: 'transactionDetails',
            path: '/merchant/transaction/transactionDetails/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/Transaction/TransactionDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Transaction/TransactionDetails').default,
            Routes: [require('../Authorized').default],
            authority: ['transactions_show'],
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'transactionDetailsEC',
            path: '/merchant/transaction/transactionDetailsEC/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/Transaction/TransactionDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Transaction/TransactionDetails').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_transactions_ec'],
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'transactionDetailsEn',
            path: '/merchant/transactionEn/transactionDetails/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/Transaction/TransactionDetailsEn'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Transaction/TransactionDetailsEn').default,
            Routes: [require('../Authorized').default],
            authority: ['transactions_show_en'],
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/merchant/audit',
            name: 'audit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Audit/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Audit/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_audits'],
            exact: true,
          },
          {
            path: '/merchant/export',
            name: 'export',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Export/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Export/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_export_tasks'],
            exact: true,
          },
          {
            path: '/merchant/emailCenter',
            name: 'emailCenter',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/EmailCenter/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/EmailCenter/index').default,
            Routes: [require('../Authorized').default],
            authority: ['sale_mail'],
            exact: true,
          },
          {
            path: '/merchant/orderCenter',
            name: 'orderCenter',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/OrderCenter'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/OrderCenter').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_orders'],
            exact: true,
          },
          {
            path: '/merchant/orderOutline',
            name: 'orderOutline',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/OrderOutline'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/OrderOutline').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_orders'],
            exact: true,
          },
          {
            name: 'orderDetails',
            path: '/merchant/orderCenter/orderDetails/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/OrderCenter/OrderDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/OrderCenter/OrderDetails').default,
            hideInMenu: true,
            Routes: [require('../Authorized').default],
            authority: ['orders_show', 'manage_saas_disputes'],
            exact: true,
          },
          {
            name: 'orderDetailsEC',
            path: '/merchant/orderCenter/orderDetailsEC/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/OrderCenter/OrderDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/OrderCenter/OrderDetails').default,
            hideInMenu: true,
            Routes: [require('../Authorized').default],
            authority: ['orders_ec_show'],
            exact: true,
          },
          {
            path: '/merchant/code',
            name: 'code',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Code'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Code').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_google_verify_code'],
            exact: true,
          },
        ],
      },
      {
        name: 'manage',
        icon: 'book',
        path: '/manage',
        authority: [
          'manage_disputes',
          'manage_xborder_dispute',
          'manage_saas_disputes',
          'disputes_reply',
          'disputes_show',
          'manage_evidences',
          'manage_xborder_chargeback',
          'saas_dispute_show',
        ],
        routes: [
          {
            path: '/manage/dispute',
            name: 'dispute',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Dispute/Dispute'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Dispute/Dispute').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_disputes'],
            exact: true,
          },
          {
            name: 'disputeDetails',
            path: '/manage/dispute/disputeDetails/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Dispute/DisputeDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Dispute/DisputeDetails').default,
            hideInMenu: true,
            Routes: [require('../Authorized').default],
            authority: ['disputes_show'],
            exact: true,
          },
          {
            path: '/manage/evidence',
            name: 'evidence',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Evidence/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Evidence/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_evidences'],
            exact: true,
          },
          {
            path: '/manage/xborder',
            name: 'Xborder',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Xborder/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Xborder/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_xborder_dispute'],
            exact: true,
          },
          {
            path: '/manage/xborder/details/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Xborder/XborderDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Xborder/XborderDetails').default,
            hidden: true,
            Routes: [require('../Authorized').default],
            authority: ['manage_xborder_dispute'],
            exact: true,
          },
          {
            path: '/manage/xborderchargeback',
            name: 'chargeback',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Merchant/XborderChargeback/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/XborderChargeback/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_xborder_chargeback'],
            exact: true,
          },
          {
            path: '/manage/saas',
            name: 'saas',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Saas/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Saas/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_saas_disputes'],
            exact: true,
          },
          {
            path: '/manage/saas/details/:data',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/audit.js').then(
                      m => {
                        return { namespace: 'audit', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/code.js').then(
                      m => {
                        return { namespace: 'code', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/developer.js').then(
                      m => {
                        return { namespace: 'developer', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/dispute.js').then(
                      m => {
                        return { namespace: 'dispute', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/email_center.js').then(
                      m => {
                        return { namespace: 'email_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/evidence.js').then(
                      m => {
                        return { namespace: 'evidence', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/gateway.js').then(
                      m => {
                        return { namespace: 'gateway', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order_center.js').then(
                      m => {
                        return { namespace: 'order_center', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/order.js').then(
                      m => {
                        return { namespace: 'order', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/origin_payment.js').then(
                      m => {
                        return { namespace: 'origin_payment', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/refundLabel.js').then(
                      m => {
                        return { namespace: 'refundLabel', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/saas.js').then(
                      m => {
                        return { namespace: 'saas', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/sub.js').then(
                      m => {
                        return { namespace: 'sub', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/transaction.js').then(
                      m => {
                        return { namespace: 'transaction', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborder.js').then(
                      m => {
                        return { namespace: 'xborder', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Merchant/models/xborderChargeback.js').then(
                      m => {
                        return { namespace: 'xborderChargeback', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Merchant/Saas/SaasDetails'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Merchant/Saas/SaasDetails').default,
            hidden: true,
            Routes: [require('../Authorized').default],
            authority: ['saas_dispute_show'],
            exact: true,
          },
        ],
      },
      {
        name: 'operation',
        icon: 'user',
        path: '/operation',
        authority: [
          'operate_manage_order_export',
          'operate_orders_export_tasks',
          'manage_logistics_refund',
          'transaction_report_list',
          'xborder_order_success_rate',
        ],
        routes: [
          {
            path: '/operation/orderExport',
            name: 'orderExport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
                      m => {
                        return { namespace: 'orderExport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
                      m => {
                        return { namespace: 'successRate', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Operation/OrderExport'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Operation/OrderExport').default,
            Routes: [require('../Authorized').default],
            authority: ['operate_manage_order_export'],
            exact: true,
          },
          {
            path: '/operation/export',
            name: 'ExportTask',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
                      m => {
                        return { namespace: 'orderExport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
                      m => {
                        return { namespace: 'successRate', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Operation/OperationExport'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Operation/OperationExport').default,
            Routes: [require('../Authorized').default],
            authority: ['operate_orders_export_tasks'],
            exact: true,
          },
          {
            path: '/operation/successRate',
            name: 'successRate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
                      m => {
                        return { namespace: 'orderExport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
                      m => {
                        return { namespace: 'successRate', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Operation/SuccessRate'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Operation/SuccessRate').default,
            Routes: [require('../Authorized').default],
            authority: ['xborder_order_success_rate'],
            exact: true,
          },
          {
            path: '/operation/refund',
            name: 'refund',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
                      m => {
                        return { namespace: 'orderExport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
                      m => {
                        return { namespace: 'successRate', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Operation/LogisticsRefund'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Operation/LogisticsRefund').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_logistics_refund'],
            exact: true,
          },
          {
            path: '/operation/collectionreport',
            name: 'collectionreport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/export.js').then(
                      m => {
                        return { namespace: 'export', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/orderExport.js').then(
                      m => {
                        return { namespace: 'orderExport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Operation/models/successRate.js').then(
                      m => {
                        return { namespace: 'successRate', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Operation/CollectionReport'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Operation/CollectionReport').default,
            Routes: [require('../Authorized').default],
            authority: ['transaction_report_list'],
            exact: true,
          },
        ],
      },
      {
        name: 'template',
        icon: 'book',
        path: '/template',
        authority: [
          'manage_dispute_reply_template',
          'manage_xborder_reply_keyword',
          'manage_xborder_reply_template',
          'keyword',
          'manage_mail_template',
          'replay_tpl_list',
        ],
        routes: [
          {
            path: '/template/paypal',
            name: 'paypal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Paypal/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Paypal/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_dispute_reply_template'],
            exact: true,
          },
          {
            path: '/template/email',
            name: 'email',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Email/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Email/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_mail_template'],
            exact: true,
          },
          {
            path: '/template/email/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Email/AddModal'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Email/AddModal').default,
            Routes: [require('../Authorized').default],
            exact: true,
          },
          {
            path: '/template/email/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Email/AddModal'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Email/AddModal').default,
            Routes: [require('../Authorized').default],
            exact: true,
          },
          {
            path: '/template/keyword',
            name: 'keyword',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/KeyWord/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/KeyWord/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_xborder_reply_keyword'],
            exact: true,
          },
          {
            path: '/template/xborder',
            name: 'xborder',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Reply/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Reply/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_xborder_reply_template'],
            exact: true,
          },
          {
            path: '/template/management',
            name: 'management',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/Management/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Management/index').default,
            Routes: [require('../Authorized').default],
            authority: ['replay_tpl_list'],
            exact: true,
          },
          {
            path: '/template/keyword_for_reply',
            name: 'keyword_for_reply',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/email.js').then(
                      m => {
                        return { namespace: 'email', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/keywords.js').then(
                      m => {
                        return { namespace: 'keywords', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/reply.js').then(
                      m => {
                        return { namespace: 'reply', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Template/models/template.js').then(
                      m => {
                        return { namespace: 'template', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Template/KeywordLibrary/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/KeywordLibrary/index').default,
            Routes: [require('../Authorized').default],
            authority: ['keyword'],
            exact: true,
          },
        ],
      },
      {
        name: 'dailyReport',
        icon: 'form',
        path: '/dailyReport',
        Routes: [require('../Authorized').default],
        authority: [
          'reports_transactions',
          'reports_disputes',
          'reports_audits',
        ],
        routes: [
          {
            path: '/dailyReport/transactionReport',
            name: 'transactionReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/auditReport.js').then(
                      m => {
                        return { namespace: 'auditReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/disputeReport.js').then(
                      m => {
                        return { namespace: 'disputeReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/report.js').then(
                      m => {
                        return { namespace: 'report', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/transactionReport.js').then(
                      m => {
                        return { namespace: 'transactionReport', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../DailyReport/TransactionReport/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../DailyReport/TransactionReport/index').default,
            Routes: [require('../Authorized').default],
            authority: ['reports_transactions'],
            exact: true,
          },
          {
            path: '/dailyReport/disputeReport',
            name: 'disputeReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/auditReport.js').then(
                      m => {
                        return { namespace: 'auditReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/disputeReport.js').then(
                      m => {
                        return { namespace: 'disputeReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/report.js').then(
                      m => {
                        return { namespace: 'report', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/transactionReport.js').then(
                      m => {
                        return { namespace: 'transactionReport', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../DailyReport/DisputeReport/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../DailyReport/DisputeReport/index').default,
            Routes: [require('../Authorized').default],
            authority: ['reports_disputes'],
            exact: true,
          },
          {
            path: '/dailyReport/auditReport',
            name: 'auditReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/auditReport.js').then(
                      m => {
                        return { namespace: 'auditReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/disputeReport.js').then(
                      m => {
                        return { namespace: 'disputeReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/report.js').then(
                      m => {
                        return { namespace: 'report', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/transactionReport.js').then(
                      m => {
                        return { namespace: 'transactionReport', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../DailyReport/AuditReport/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../DailyReport/AuditReport/index').default,
            Routes: [require('../Authorized').default],
            authority: ['reports_audits'],
            exact: true,
          },
          {
            path: '/dailyReport/uploadReport',
            name: 'uploadReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/auditReport.js').then(
                      m => {
                        return { namespace: 'auditReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/disputeReport.js').then(
                      m => {
                        return { namespace: 'disputeReport', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/report.js').then(
                      m => {
                        return { namespace: 'report', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/DailyReport/models/transactionReport.js').then(
                      m => {
                        return { namespace: 'transactionReport', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../DailyReport/UploadReport'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../DailyReport/UploadReport').default,
            Routes: [require('../Authorized').default],
            authority: ['reports_audits'],
            exact: true,
          },
        ],
      },
      {
        name: 'person',
        icon: 'user',
        path: '/person',
        routes: [
          {
            path: '/person/setting',
            name: 'usermessage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../PersonalSetting/UserMessage'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../PersonalSetting/UserMessage').default,
            exact: true,
          },
          {
            path: '/person/reset',
            name: 'userpwd',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../PersonalSetting/ChangePwd'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../PersonalSetting/ChangePwd').default,
            exact: true,
          },
        ],
      },
      {
        name: 'email',
        icon: 'mail',
        path: '/email',
        authority: ['paypal-email-list', 'paypal-email-regx-list'],
        routes: [
          {
            path: '/email/criticalMails',
            name: 'criticalMails',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Email/CriticalMails/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Email/CriticalMails/index').default,
            Routes: [require('../Authorized').default],
            authority: ['paypal-email-list'],
            exact: true,
          },
          {
            path: '/email/emailRule',
            name: 'emailRule',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Email/EmailRule/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Email/EmailRule/index').default,
            Routes: [require('../Authorized').default],
            authority: ['paypal-email-regx-list'],
            exact: true,
          },
        ],
      },
      {
        name: 'setting',
        icon: 'setting',
        path: '/setting',
        routes: [
          {
            path: '/setting/role',
            name: 'role',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Set/Role/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/Role/index').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_roles'],
            exact: true,
          },
          {
            path: '/setting/customerService',
            name: 'customerService',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Set/CustomerService/CustomerService'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/CustomerService/CustomerService').default,
            Routes: [require('../Authorized').default],
            authority: ['manage_users'],
            exact: true,
          },
          {
            path: '/setting/customerService/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Set/CustomerService/AddCustomerService'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/CustomerService/AddCustomerService').default,
            Routes: [require('../Authorized').default],
            authority: ['users_store'],
            exact: true,
          },
          {
            path: '/setting/customerService/edit/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../Set/CustomerService/EditCustomerService'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/CustomerService/EditCustomerService').default,
            Routes: [require('../Authorized').default],
            authority: ['users_edit'],
            exact: true,
          },
          {
            path: '/setting/automationRules',
            name: 'automationRules',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Set/AutomationRules/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/AutomationRules/index').default,
            Routes: [require('../Authorized').default],
            exact: true,
          },
          {
            path: '/setting/automaticReplyRules',
            name: 'automaticReplyRules',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/customerService.js').then(
                      m => {
                        return { namespace: 'customerService', ...m.default };
                      },
                    ),
                    import('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/pages/Set/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Set/AutomaticReplyRules/index'),
                  LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Set/AutomaticReplyRules/index').default,
            Routes: [require('../Authorized').default],
            authority: ['replay_rule_list'],
            exact: true,
          },
        ],
        authority: [
          'manage_roles',
          'manage_users',
          'users_store',
          'users_edit',
          'replay_rule_list',
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404'),
              LoadingComponent: require('/builds/neaufmaC/0/lf/aftersell/paycloak-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
